import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import install from "./libs/install";
import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue';
import './assets/css/base.scss';

Vue.config.productionTip = false;

Vue.use(install);
Vue.use(Antd);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
