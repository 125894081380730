import config from "@/config/config";
import moment from "moment";
import { VNode } from "vue";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import BaseView from "../cores/BaseView";
import ObjectUtil from "../utils/ObjectUtil";
import StringUtil from "../utils/StringUtil";


@Component
export default class XForm extends BaseView {
  @Prop()
  public readonly datas?: any;

  @Prop({ type: Object, default: () => {return {};} })
  public readonly type?: Object;

  @Prop({ type: String, default: "" })
  public readonly action?: string;

  private formRef: any = null;

  private formObj: Array<any> = new Array<any>();

  private formDatas: any = {};

  private formRules: any = [];

  private submitLoadding: boolean = false;

  @Prop({ type: Function, default: async (form: Object): Promise<boolean> => {return true;}})
  public readonly submit?: any;

  @Emit('formAction') formAction(obj: Object, filed: string, action: string): void {}

  async initData(): Promise<boolean> {
    this.formObj = await this.getObjFromAnno(this.type);
    this.initFormData();
    return true;
  }

  async getObjFromAnno(fromType: any): Promise<any> {
    if (StringUtil.isEmpty(fromType)) {
      return {};
    }
    const returnObj: Array<any> = new Array<any>();
    const anno = fromType._annotation;

    let tmpInserObj = [];
    
    for(const key of anno.keys()) {
      const val = anno.get(key);
      let objAnno = null;

      if (this.action == "search") {
        objAnno = val.Search;
      } else {
        if (!StringUtil.isEmpty(val.rule)) {
          const tmpRule: any = [];
          tmpRule[key] = val.rule;
          this.formRules = Object.assign({}, this.formRules, tmpRule);
        }

        if (StringUtil.isEmpty(this.datas)) {
          objAnno = val.AddForm;
        } else {
          objAnno = val.EditForm;
        }
      }

      if (StringUtil.isEmpty(objAnno)) {
        continue;
      }

      const inLine = objAnno.inLine || false;
      let selectDatas = null;

      if (objAnno.selectDatas) {
        selectDatas = await objAnno.selectDatas(ObjectUtil.deepCopy(this.datas));
      }

      let annoItem = null;
      if (objAnno.bindTYpe) {
        const SO = new objAnno.bindTYpe();
        annoItem = await this.getObjFromAnno(SO);
      }
      
      let obj = {
        name: val.FieldName,
        key: key,
        input: objAnno.input,
        inputType: objAnno.type,
        anno: objAnno,
        annoType: annoItem,
        selectDatas: selectDatas
      };

      if (!StringUtil.isEmpty(val.width)) {
        obj = Object.assign({}, obj, {width: val.width});
      }

      if (inLine) {
        tmpInserObj.push(obj);
        if (tmpInserObj.length == 2) {
          returnObj.push(tmpInserObj);
          tmpInserObj = [];
        }
      } else {
        if (tmpInserObj.length > 0) {
          returnObj.push(tmpInserObj);
        }
        
        tmpInserObj = [];
        returnObj.push(obj);
      }
    }

    return returnObj;
  }

  public initFormData(): void {
    if (StringUtil.isEmpty(this.datas)) {
      this.formDatas = {};
    } else {
      this.formDatas = ObjectUtil.deepCopy(this.datas);
    }
    
    this.formObj.map((objItem: any) => {
      if (ObjectUtil.isArray(objItem)) {
        objItem.map((subObjItem: any) => {
          this.initFromDataValu(subObjItem);
        });
      } else {
        this.initFromDataValu(objItem);
      }
    });
  }

  public initFromDataValu(objItem: any): void {
    const anno = objItem.anno;
    if (anno?.multiple && (objItem.input != "select" && objItem.input != "image")) {
      let tmpObj: any = "";
      if (objItem.input == "object") {
        tmpObj = new anno.bindTYpe();
      }
      
      this.setFormValue(objItem, tmpObj, null, false);
    }

    // if (objItem.input == "select" && objItem.anno.selectChange) {
    //   objItem.anno.selectChange.call(this, objItem.selectDatas.filter((sItem: any) => sItem.key == this.formDatas[objItem.key])[0], this.selectChangeCallback(objItem, null), false);
    // }
    // if (item.anno.selectChange) {
    //   item.anno.selectChange.call(this, item.selectDatas.filter((sItem: any) => sItem.key == val)[0], this.selectChangeCallback(item, parentItem, parentPos));
    // }
  }

  public getFormInput(item: any): VNode {
    if (item.anno.visible) {
      const isVisoble = item.anno.visible(this.formDatas);
      if (!isVisoble) {
        return (<div></div>);
      }
    }

    return (
      <a-form-model-item
        key={item.key}
        prop={item.key}
        label={item.name}
      >
        {this.getInputHtml(item)}
      </a-form-model-item>
    );
  }

  /**
   * 获取控件html
   * @param item 当前属性
   * @param parentItem 父属性
   * @param parentPos 父定位
   * @returns 
   */
  public getInputHtml(item: any, parentItem?: any, parentPos?: number): VNode | null {
    let inputHtml = null;
    switch (item.input as string) {
      case "text":
        inputHtml = this.getTextInput(item, parentItem, parentPos);
        break;
      case "select":
        inputHtml = this.getSelectInput(item, parentItem, parentPos);
        break;
      case "date":
        inputHtml = this.getDateInput(item, parentItem, parentPos);
        break;
      case "time":
        inputHtml = this.getTimeInput(item, parentItem, parentPos);
        break;
      case "textarea":
        inputHtml = this.getTextAreaInput(item, parentItem, parentPos);
        break;
      case "file":
        inputHtml = this.getFileInput(item, parentItem, parentPos);
        break;
      case "image":
        inputHtml = this.getImageInput(item, parentItem, parentPos);
        break;
      case "object":
        inputHtml = this.getObjectInput(item);
        break;
      case "html":
        inputHtml = this.getHtmlInput(item, parentItem, parentPos);
        break;
      default:
        inputHtml = null;
        break;
    }
    return inputHtml;
  }

  /**
   * 设置表单值
   * @param item 当前属性
   * @param val 属性值
   * @param parentItem 父属性
   * @param isUpdate 是否更新
   * @param pos 位置
   * @param targetKey 目标字段名
   */
  public setFormValue(item: any, val: any, parentItem?: any, isUpdate: boolean = true, pos?: number, targetKey?: string): void {
    let key = "";
    let subKey = "";
    let resValue = null;
    let input = "";
    let anno = null;
    if (!StringUtil.isEmpty(parentItem)) {
      key = parentItem.key;
      subKey = targetKey ? targetKey : item.key;
      resValue = this.formDatas[key];
      input = parentItem.input;
      anno = parentItem.anno;
    } else {
      key = targetKey ? targetKey : item.key;
      resValue = this.formDatas[key];
      input = item.input;
      anno = item.anno;
    }

    if (anno.multiple && anno.input != "image") { // 处理数组字段
      if (StringUtil.isEmpty(resValue)) {
        resValue = new Array<any>();
      }
      
      if (ObjectUtil.isArray(resValue)) {
        if (!StringUtil.isEmpty(parentItem)) {
          const SO = new anno.bindTYpe();
          SO[subKey] = val;
          if (pos !== undefined && resValue.length > pos) {
            const oldVal = resValue[pos];
            oldVal[subKey] = val;
            resValue[pos] = oldVal;
          } else {
            resValue.push(SO);
          }
        } else {
          if (anno.input == "select" && ObjectUtil.isArray(val)) {
            resValue = val.filter((item: any) => !StringUtil.isEmpty(item));
          } else {
            if (pos !== undefined && resValue.length > pos) {
              resValue[pos] = val;
            } else {
              resValue.push(val);
            }
          }
        }
      }
    } else {// 处理非数组字段 
      if (!StringUtil.isEmpty(parentItem)) {
        if (StringUtil.isEmpty(resValue)) {
          resValue = new anno.bindTYpe();
        }
        resValue[subKey] = val;
      } else {
        resValue = val;
      }
    }
    
    this.$set(this.formDatas, key, resValue); //正确赋值

    if (isUpdate) {
      this.$forceUpdate();
    }
  }

  /**
   * 获取默认值
   * @param item 当前属性
   * @param parentItem 父属性
   * @param pos 位置
   * @param targetKey 目标字段名 
   * @returns 
   */
  public getDefaltVal(item: any, parentItem?: any, pos?: number, targetKey?: string): string | number | null | any[] {
    let key = "";
    let subKey = "";
    let resValue: any = {};
    let input = "";
    let anno = null;

    if (StringUtil.isEmpty(this.formDatas)) {
      return "";
    } else {
      resValue = this.formDatas;
    }
    if (!StringUtil.isEmpty(parentItem)) {
      key = parentItem.key;
      subKey = targetKey ? targetKey : item.key;
      resValue = resValue[key];
      input = parentItem.input;
      anno = parentItem.anno;
    } else {
      key = targetKey ? targetKey : item.key;
      resValue = resValue[key];
      input = item.input;
      anno = item.anno;
    }
    
    if (anno.multiple && anno.input != "image") { // 处理数组字段
      if (ObjectUtil.isArray(resValue)) {
        if (!StringUtil.isEmpty(parentItem)) {
          if (pos !== undefined && resValue.length > pos) {
            const oldVal = resValue[pos];
            resValue = oldVal[subKey]
          } else {
            resValue = "";
          }
        } else {
          if (anno.input != "select") {
            if (pos !== undefined && resValue.length > pos) {
              resValue = resValue[pos];
            } else {
              resValue = "";
            }
          }
        }
      }
    } else {// 处理非数组字段 
      if (!StringUtil.isEmpty(parentItem)) {
        if (StringUtil.isEmpty(resValue)) {
          resValue = new anno.bindTYpe();
        }
        resValue = resValue[subKey];
      }
    }

    if (StringUtil.isEmpty(resValue)) {
      return "";
    } else {
      return resValue;
    }
    
  }

  /**
   * 清除表单值
   * @param item 当前属性
   * @param parentItem 父属性 
   * @param isUpdate 是否更新
   * @param pos 位置
   */
  public clearFormValue(item: any, parentItem?: any, isUpdate: boolean = true, pos?: number): void {
    let key = "";
    let resValue = null;
    let anno = null;
    if (!StringUtil.isEmpty(parentItem)) {
      key = parentItem.key;
      resValue = this.formDatas[key];
    } else {
      key = item.key;
      resValue = this.formDatas[key];
      anno = item.anno;
    }

    if (anno.multiple) {
      if (ObjectUtil.isArray(resValue)) {
        if (pos !== undefined && resValue.length > pos) {
          resValue.splice(pos, 1);
        }
        this.$set(this.formDatas, item.key, resValue); //正确赋值
      }
    } else {
      delete this.formDatas[item.key];
    }
    
    if (isUpdate) {
      this.$forceUpdate();
    }
  }

  /**
   * 处理文本输入框
   * @param item 
   * @returns 
   */
   public getTextInput(item: any, parentItem?: any, parentPos?: number): VNode {
    const anno = item.anno;
    if (anno.multiple) {
      const val = this.formDatas[item.key];
      const addClick = () => {
        this.setFormValue(item, "", parentItem);
      }

      const deleteClick = (pos: number) => {
        this.clearFormValue(item, parentItem, true, pos);
      }

      const changeInput = (e: any, pos: number) => {
        let tagetValue = e.target.value;

        if (item.inputType == "number") {
          tagetValue = tagetValue.replace(/[^\d]/g,'');
        }
        this.setFormValue(item, tagetValue, parentItem, true, pos);
      }

      return (
        <a-space direction="vertical" style="width: 100%" key={"mul_" + item.key + "_" + this.formDatas[item.key].length}>
          {this.formDatas[item.key].map((litItem: any, index: number) => (
            <a-row gutter={24} key={item.key + "_" + index}>
              <a-col span="20"><a-input onChange={(e: any) => changeInput(e, index)} placeholder={item.name} value={this.getDefaltVal(item, parentItem, index)}></a-input></a-col>
              <a-col span="3">
                {index == val.length - 1 ? (
                  <a-button type="primary" shape="circle" icon="plus" onClick={addClick} />
                ) : (
                  <a-button type="danger" shape="circle" icon="minus" onClick={() => deleteClick(index)} />
                )}
              </a-col>
            </a-row>
            ))}
        </a-space>
      )
    } else {
      const changeInput = (e: any) => {
        let tagetValue = e.target.value;

        if (item.inputType == "number") {
          tagetValue = tagetValue.replace(/[^\d]/g,'');
        }
        this.setFormValue(item,tagetValue, parentItem, true, parentPos);
      }
      return <a-input placeholder={item.name} onChange={(e: any) => changeInput(e)} value={this.getDefaltVal(item, parentItem, parentPos)}></a-input>;
    }
  }

  public getTextAreaInput(item: any, parentItem?: any, parentPos?: number): VNode {
    const changeInput = (e: any) => {
      this.setFormValue(item, e.target.value, parentItem, false, parentPos);
    }
    return <a-textarea placeholder={item.name} rows={4} onChange={(e: any) => changeInput(e)} defaultValue={this.getDefaltVal(item, parentItem, parentPos)}></a-textarea>;
  }

  public selectChangeCallback(item: any, parentItem?: any, parentPos?: number, setEpmty: boolean = true): (key: string, val: any, isBind: boolean) => void {
    return (key: string, val: any, isBind: boolean = false) => {
      if (isBind) {
        this.formObj = this.formObj.map((objItem: any) => {
          if (ObjectUtil.isArray(objItem)) {
            objItem = objItem.map((subObjItem: any) => {
              if (subObjItem.key == key) {
                subObjItem.selectDatas = val;
              }
              return subObjItem;
            });
          } else {
            if (objItem.key == key) {
              objItem.selectDatas = val;
            }
          }
          return objItem;
        });
        
        if (setEpmty) {
          this.setFormValue(item, "", parentItem, true, parentPos, key);
        }
        
      } else {
        this.setFormValue(item, val, parentItem, true, parentPos, key);
      }
    }
  }

  public getSelectInput(item: any, parentItem?: any, parentPos?: number): VNode {
    const changeInput = (val: any) => {
      if (item.anno.selectChange) {
        item.anno.selectChange.call(this, item.selectDatas.filter((sItem: any) => sItem.key == val)[0], this.selectChangeCallback(item, parentItem, parentPos), this.formDatas);
      }
      
      this.setFormValue(item, val, parentItem, true, parentPos);
    }

    let defaultVal: any = this.getDefaltVal(item, parentItem, parentPos);
    if (item.anno.multiple) {
      if (StringUtil.isEmpty(defaultVal)) {
        defaultVal = [];
      } else {
        defaultVal = defaultVal.filter((deval: any) => !StringUtil.isEmpty(deval));
      }
    }

    return (
      <a-select mode={item.anno.multiple ? "multiple" : "default"} placeholder={item.name} value={defaultVal} onChange={changeInput}>
        {!item.anno.multiple && (<a-select-option value="">请选择{item.name}</a-select-option>)}
        {item.selectDatas.map((opt: any) => (
          <a-select-option value={opt.key}>
            {opt.val}
          </a-select-option>
        ))}
      </a-select>
    );
  }

  

  public getDateInput(item: any, parentItem?: any, parentPos?: number): VNode {
    const anno = item.anno;

    const changeInput = (date: any, dateString: any) => {
      this.setFormValue(item, dateString + " 00:00:00", parentItem, true, parentPos);
    }

    
    let defaultVal: any = this.getDefaltVal(item, parentItem, parentPos);
    if (StringUtil.isEmpty(defaultVal)) {
      defaultVal = null;
    } else {
      defaultVal = moment(defaultVal);
    }

    return (
      <a-date-picker defaultValue={defaultVal} placeholder={item.name} onChange={changeInput} format="YYYY-MM-DD" />
    );
  }

  public getTimeInput(item: any, parentItem?: any, parentPos?: number): VNode {
    const anno = item.anno;

    const changeInput = (date: any, dateString: any) => {
      this.setFormValue(item, date.format("YYYY-MM-DDTHH:mm:ss"), parentItem, true, parentPos);
    }

    let defaultVal: any = this.getDefaltVal(item, parentItem, parentPos);
    if (StringUtil.isEmpty(defaultVal)) {
      defaultVal = null;
    } else {
      defaultVal = moment(defaultVal);
      // defaultVal = moment().format("HH:mm");
    }

    return (
      <a-time-picker defaultValue={defaultVal} format="HH:mm" placeholder={item.name} onChange={changeInput} />
    );
  }

  public getFileInput(item: any, parentItem?: any, parentPos?: number): VNode {
    const defaultFileList: any[] = [];

    const defaultVal = this.getDefaltVal(item, parentItem, parentPos) as string;
    if (!StringUtil.isEmpty(defaultVal)) {
      defaultVal.split(",").map((fileItem: string, fileUndex: number) => {
        const tmpFileName = fileItem.split("/");
        const fileName = tmpFileName[tmpFileName.length - 1];
        defaultFileList.push({
          uid: fileUndex,
          name: fileName,
          status: 'done',
          url: fileItem,
          isUrl: true
        });
      });
    }

    const changeFile = (date: any) => {
      if (date.file.status === 'uploading') {
        //do something
      } else if (date.file.status === 'done') {
        const addValue: any[] = [];
        date.fileList.map((tmpItem: any) => {
          if (tmpItem.isUrl) {
            addValue.push(tmpItem.url);
          } else {
            addValue.push(tmpItem.response.data);
          }
        });
        this.setFormValue(item, addValue.join(","), parentItem, true, parentPos);
      } else if (date.file.status === 'error') {
        this.$message.error(`${date.file.name} file upload failed.`);
      } else if (date.file.status === 'removed') {
        const addValue: any[] = [];
        date.fileList.map((tmpItem: any) => {
          if (tmpItem.isUrl) {
            addValue.push(tmpItem.url);
          } else {
            addValue.push(tmpItem.response.data);
          }
        });
        this.setFormValue(item, addValue.join(","), parentItem, true, parentPos);
      }
    }

    return (
      <a-upload
        action={config.baseURL + config.uploadUrl}
        data={{savePath: StringUtil.timestampToTime(new Date().getTime(), "yyyyMM")}}
        multiple={true}
        onChange={changeFile}
        default-file-list={defaultFileList}
      >
        <a-button type="primary" icon="upload">上传文件 </a-button>
      </a-upload>
    );
  }

  public getImageInput(item: any, parentItem?: any, parentPos?: number): VNode {
    const defaultVal = this.getDefaltVal(item, parentItem, parentPos) as string;
    let isLoading = false;
    if (item.anno.multiple) {
      const defaultFileList: any[] = [];
      if (!StringUtil.isEmpty(defaultVal)) {
        defaultVal.split(",").map((fileItem: string, fileUndex: number) => {
          const tmpFileName = fileItem.split("/");
          const fileName = tmpFileName[tmpFileName.length - 1];
          defaultFileList.push({
            uid: fileUndex,
            name: fileName,
            status: 'done',
            url: fileItem,
            isUrl: true
          });
        });
      }
      const changeFile = (date: any) => {
        if (date.file.status === 'uploading') {
          // isLoading = true;
        } else if (date.file.status === 'done') {
          const addValue: any[] = [];
          date.fileList.map((tmpItem: any) => {
            if (tmpItem.isUrl) {
              addValue.push(tmpItem.url);
            } else {
              addValue.push(tmpItem.response.data);
            }
          });

          this.setFormValue(item, addValue.join(","), parentItem, true, parentPos);
        }
      }

      return (
        <x-div width="100%" flex="row|wrap" class="uploadWrapper">
          {defaultFileList.map((fileItem: any) => (
            <x-div width="128px" height="128px"marginRight="8px" marginBottom="8px" border="1px dashed #d9d9d9" bgColor="#fafafa" flex="shrink">
              <img src={config.baseURL + "/common/fileDownload?key=" + fileItem.url} width="100%" height="100%" />
            </x-div>
          ))}
          <a-upload
            action={config.baseURL + config.uploadUrl}
            listType="picture-card"
            class="avatar-uploader"
            data={{savePath: StringUtil.timestampToTime(new Date().getTime(), "yyyyMM")}}
            showUploadList={false}
            onChange={changeFile}
          >
            <x-div wdith="100%" height="100%" flex="col|center">
              <a-icon type={isLoading ? "loading" : "plus"} />
              <div class="ant-upload-text">
                Upload
              </div>
            </x-div>
          </a-upload>
        </x-div>
      );
    } else {
      const changeFile = (date: any) => {
        if (date.file.status === 'uploading') {
          isLoading = true;
        } else if (date.file.status === 'done') {
          isLoading = false;
          this.setFormValue(item, date.file.response.data, parentItem, true, parentPos);
        }
      }
  
      return (
        <a-upload
          action={config.baseURL + config.uploadUrl}
          listType="picture-card"
          class="avatar-uploader"
          data={{savePath: StringUtil.timestampToTime(new Date().getTime(), "yyyyMM")}}
          multiple={false}
          onChange={changeFile}
          showUploadList={false}
        >
          {StringUtil.isEmpty(defaultVal) ? (
            <x-div wdith="100%" height="100%" flex="col|center">
              <a-icon type={isLoading ? "loading" : "plus"} />
              <div class="ant-upload-text">
                Upload
              </div>
            </x-div>
          ) : (
            <x-div wdith="120px" height="120px" flex="col|center">
              <img src={config.baseURL + "/common/fileDownload?key=" + defaultVal} width="100%" height="100%" />
            </x-div>
          )}
        </a-upload>
      );
    }
    
  }

  public getObjectInput(item: any): VNode {
    if (item.annoType) {
      const annoItem = item.annoType;
      const val = this.formDatas[item.key];

      if (item.anno.multiple) {
        const addEvent = () => {
          this.setFormValue(item, new item.anno.bindTYpe(), null, true);
        }

        const deleteClick = (pos: number) => {
          const itemValue = val[pos]
          this.clearFormValue(item, null, true, pos);
          this.formAction(itemValue, item.key, "delete")
        }

        return (
          <a-space direction="vertical" style="width: 100%;">
            {/* <a-button type="primary" icon="plus" onClick={() => addEvent()}>添加{item.name} </a-button> */}
            {val?.map((litItem: any, index: number) => (
              <x-div width="100%" flex="row|center-y" key={"object_" + item.key + val.length + "_" + index}>
                <x-div width="100%" border="1px solid #dddddd" padding="10px" marginRight={10} flex="row|grow">
                  <x-div flex="grow" marginRight={10}>
                    <a-space direction="vertical" style="width: 100%;">
                      {annoItem?.map((itemObj: any) => {
                        if (ObjectUtil.isArray(itemObj)) {
                          return (
                            <a-row gutter={24}>
                              {itemObj.map((subItem: any) => {
                                return (
                                  <a-col span="12">
                                    {this.getInputHtml(subItem, item, index)}
                                  </a-col>
                                );
                              })}
                              
                            </a-row>
                          );
                        } else {
                          return this.getInputHtml(itemObj, item, index);
                        }
                      })}
                    </a-space>
                  </x-div>
                </x-div>
                {index < val.length - 1 ? (<a-button type="danger" icon="minus" onClick={() => deleteClick(index)} />) : (<a-button type="primary" icon="plus" onClick={() => addEvent()} />)}
              </x-div>
            ))}
          </a-space>
      );
      } else {
        return (<x-div width="100%" border="1px solid #dddddd" padding="10px" flex="col">
        <a-space direction="vertical">
          {annoItem.map((itemObj: any) => {
            if (ObjectUtil.isArray(itemObj)) {
              return (
                <a-row gutter={24}>
                  {itemObj.map((subItem: any) => {
                    return (
                      <a-col span="12">
                        {this.getInputHtml(subItem, item)}
                      </a-col>
                    );
                  })}
                  
                </a-row>
              );
            } else {
              return this.getInputHtml(itemObj, item);
            }
          })}
        </a-space>
      </x-div>);
      }
      
    } else {
      return (<div></div>);
    }
    
  }

  public getHtmlInput(item: any, parentItem?: any, parentPos?: number): VNode {
    let val = this.getDefaltVal(item, parentItem, parentPos);
    if (item.anno.format) {
      val = item.anno.format(val);
    }
    return (
      <span>{val}</span>
    );
  }

  public clickSubmit(): void {
    this.formRef.validate(async (valid: boolean) => {
      if (valid) {
        this.submitLoadding = true;
        const res = await this.submit(this.formDatas);
        this.submitLoadding = !res;
      } else {
        return false;
      }
    });
  }

  public getSubmitBtns(): VNode | null {
    if (this.formObj.length > 0) {
      return this.action == "search" ? (
        <a-form-model-item>
          <a-button type="primary" onClick={() => this.clickSubmit()}>搜索</a-button>
        </a-form-model-item>
      ) : (
        <a-form-model-item>
          <x-div width="100%" flex="row|end-x">
            <a-button type="primary" loading={this.submitLoadding} onClick={() => this.clickSubmit()}>提交</a-button>
          </x-div>
        </a-form-model-item>
      );
    } else {
      return null;
    }
  }

  public view(): VNode {
    return (
      <a-form-model 
        class={this.action == "search" ? "search_form" : "add_form"}
        layout={this.action == "search" ? "inline" : "horizontal"}
        props={{
          model: this.formDatas
        }}
        rules={this.action == "search" || this.formRules.length == 0 ? {} : this.formRules}
        ref={(ref: any) => this.formRef = ref}
        >
        {this.formObj.map(item => {
          if (ObjectUtil.isArray(item)) {
            return (
              <a-row gutter={24}>
                {item.map((subItem: any) => {
                  return (
                    <a-col span="12">
                      {this.getFormInput(subItem)}
                    </a-col>
                  );
                })}
                
              </a-row>
            );
          } else {
            return this.getFormInput(item);
          }
        })}
        {this.getSubmitBtns()}
        {this.$slots.buttons}
      </a-form-model>
    );
  }
}