import { Modal } from "ant-design-vue";
import { VNode } from "vue";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import { AddFormFiled } from "../annotations/AddFormAnnotation";
import BaseView from "../cores/BaseView";
import JsonUtil from "../utils/JsonUtil";
import ObjectUtil from "../utils/ObjectUtil";
import StringUtil from "../utils/StringUtil";

@Component
export default class XTable extends BaseView {
  @Prop({ type: [String, Number], default: null })
  public readonly width?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly height?: string | number;

  @Prop({ type: Array, default: () => {return [];} })
  public readonly datas?: Array<any>;

  @Prop({ type: Object, default: () => {return {};} })
  public readonly searchParam?: Object;

  @Prop({ type: Object, default: () => {return {};} })
  public readonly type?: Object;

  @Prop({ type: Function, default: async (): Promise<any> => {return null;}})
  public readonly beforeAdd?: any;

  @Prop({ type: Function, default: async (form: Object): Promise<any> => {return null;}})
  public readonly beforeEdit?: any;

  @Prop({ type: Function, default: async (form: Object): Promise<boolean> => {return true;}})
  public readonly addSubmit?: any;

  @Prop({ type: Function, default: async (form: Object): Promise<boolean> => {return true;}})
  public readonly editSubmit?: any;

  @Prop({ type: Function, default: async (form: Object): Promise<void> => {}})
  public readonly searchSubmit?: any;

  @Prop({ type: Function, default: (page: number, pageSize: number): void => {}})
  public readonly pageChange?: any;

  @Prop({ type: Number, default: 1 })
  public readonly pageNum?: number;

  @Prop({ type: Number, default: 10 })
  public readonly pageSize?: number;

  @Prop({ type: Number, default: 0 })
  public readonly pageTotal?: number;

  @Prop({ type: Boolean, default: true })
  public readonly showAdd?: number;

  @Prop({ type: Boolean, default: true })
  public readonly showEdit?: number;

  @Prop({ type: Boolean, default: true })
  public readonly actionStatus?: number;

  @Prop({ type: Boolean, default: true })
  public readonly showDelete?: number;

  @Prop({ type: Boolean, default: true })
  public readonly showSearch?: number;

  @Prop({ type: Boolean, default: true })
  public readonly showPage?: number;

  private columns: any = {};

  private addStatus: boolean = false;

  private editStatus: boolean = false;

  private editData?: Object;

  private addData?: Object;

  private tableScope: any = {};

  // private bindDatas: any = [];

  @Emit('deleteItem') deleteItem(form: Object): void {}

  @Emit('formAction') formAction(obj: Object, filed: string, action: string): void {}

  // @Emit('beforeEdit') async beforeEdit(form: Object): Promise<any> {return null}

  async initData(): Promise<boolean> {
    this.columns = this.getColumns(this.type);
    // if (!StringUtil.isEmpty(this.datas)) {
    //   this.bindDatas = ObjectUtil.deepCopyArr(this.datas);
    // }
    
    return true;
  }

  @Watch("type", {deep: true})
  public updateTable(): void {
    this.initData();
  }

  private getColumns(col: any): any {
    const returnObj: Array<any> = new Array<any>();
    const anno = col["_annotation"];

    for(const key of anno.keys()) {
      const val = anno.get(key);
      if (!ObjectUtil.isNull(val.Table)) {
        let obj: any = {
          align: "center",
          dataIndex: key,
          key: key,
          title: val.FieldName
        };

        if (!StringUtil.isEmpty(this.$scopedSlots[key])) {
          obj.scopedSlots = {customRender: key};
          this.tableScope[key] = (text: any, item: any, index: number) => {
            const func: any = this.$scopedSlots[key];
            return func(text, item, index);
          }
        }

        if (!StringUtil.isEmpty(val.width)) {
          obj = Object.assign({}, obj, {width: val.width});
        }
        returnObj.push(obj);
      }
    }

    if (this.actionStatus) {
      returnObj.push({
        align: "center",
        dataIndex: "tableAction",
        key: "tableAction",
        title: "操作",
        width: "20%",
        scopedSlots: { customRender: 'tableAction' }
      });
    }
    
    return returnObj;
  }

  public searchForm(): VNode {
    if (!this.showSearch) {
      return (<span></span>);
    }

    let searchBottoms = null;
    if (this.$scopedSlots["searchBottons"]) {
      const searchBottonsSlot: any = this.$scopedSlots["searchBottons"];
      searchBottoms = searchBottonsSlot();
    }

    return (
      <x-div width="100%" bgColor="#FFFFFF" padding={[10, 10, 10,10]} flex="col">
        <x-form key={"search_" + new Date().getTime()} action="search" datas={this.searchParam} type={this.type} submit={(formdata: any) => {this.searchSubmit(formdata)}}>
          <template slot="buttons">
            {this.showAdd && (
              <a-form-item>
                <a-button type="primary" icon="plus" onClick={() => this.clickAdd()}>添加</a-button>
              </a-form-item>
            )}
            
            {searchBottoms}
          </template>
        </x-form>
      </x-div>
    );
  }

  async clickAdd(): Promise<void> {
    const res = await this.beforeAdd();
    if (!StringUtil.isEmpty(res)) {
      this.addData = res; 
    }
    this.addStatus = true
  }

  public addModal(): VNode | null {
    if (this.addStatus) {
      return (
        <a-modal
          title="添加"
          width="800px"
          visible={true}
          footer={null}
          maskClosable={false}
          onCancel={() => this.addStatus = false}
        >
          <x-form 
            key={"add_" + new Date().getTime()}
            type={this.type} 
            datas={this.addData} 
            submit={async (formdata: any) => {return await this.addSubmitModel(formdata);}}></x-form>
        </a-modal>
      );
    } else {
      return null;
    }
  }

  async addSubmitModel(formdata: any): Promise<boolean> {
    const res = await this.addSubmit(formdata);
    if (res) {
      this.addStatus = false;
    }
    return res;
  }

  public editModal(): VNode | null {
    if (this.editStatus) {
      return (
        <a-modal
          title="修改"
          width="800px"
          visible={true}
          footer={null}
          maskClosable={false}
          onCancel={() => this.closeEditModel()}
        >
          <x-form 
            key={"edit_" + new Date().getTime()}
            type={this.type} 
            datas={this.editData} 
            onFormAction={(obj: Object, filed: string, action: string) => this.formAction(obj, filed, action)}
            submit={async (formdata: any) => {return await this.editSubmitModel(formdata);}}></x-form>
        </a-modal>
      );
    } else {
      return null;
    }
  }

  async editSubmitModel(formdata: any): Promise<boolean> {
    const res = await this.editSubmit(formdata);
    if (res) {
      this.editStatus = false;
    }
    return res;
  }

  public closeEditModel(): void {
    this.editData = {};
    this.editStatus = false;
  }

  public deleteAction(item: any): void {
    this.$confirm({
      title: '请确认删除本条记录?',
      content: '请确认删除本条记录',
      okText: '是',
      okType: 'danger',
      cancelText: '否',
      onOk: () => {
        this.deleteItem(item);
      },
      onCancel() {
        //TOTO
      },
    });
  }

  async clickEdit(obj: any): Promise<void> {
    const res = await this.beforeEdit(obj);
    if (StringUtil.isEmpty(res)) {
      this.editData = ObjectUtil.deepCopy(obj); 
    } else {
      this.editData = res; 
    }
    this.editStatus = true
  }

  public table(): VNode {
    return (
      <x-div width="100%" padding={[10, 10, 10,10]} flex="col" bgColor="#FFFFFF">
        <a-table 
          rowKey={(record: any, index: number) => index}
          style={{width: "100%"}}
          columns={this.columns} 
          data-source={this.datas} 
          pagination={this.showPage ? {
            total: this.pageTotal,
            pageSize: this.pageSize,
            defaultCurrent: this.pageNum,
            onChange: (page: any, pageSize: any) => {this.pageChange(page, pageSize);},
            showTotal: (total: number, range: any) => "第" + range[0] + "条 - 第" + range[1] + "条（共" + total + "条）"
          } : false}
          bordered 
          locale={{emptyText: "暂无数据"}} 
          scopedSlots={{
            tableAction: (text: string, item: any, index: number) => (
              <x-div width="100%" flex="row|center">
                <a-space>
                  {/* <a-tooltip placement="bottom">
                    <template slot="title">查看</template>
                    <a-button icon="snippets" shape="circle" />
                  </a-tooltip> */}
                  {this.showEdit && (
                    <a-tooltip placement="bottom">
                      <template slot="title">修改</template>
                      <a-button icon="edit" shape="circle" type="primary" onClick={() => {this.clickEdit(item)}} />
                    </a-tooltip>
                  )}

                  {this.showDelete && (
                    <a-tooltip placement="bottom">
                      <template slot="title">删除</template>
                      <a-button icon="delete" shape="circle" type="danger" onClick={() => {this.deleteAction(item);}} />
                    </a-tooltip>
                  )}
                  {this.$scopedSlots["actions"] && (this.$scopedSlots["actions"] as any)(text, item, index)}
                </a-space>
              </x-div>
            ),
            ...this.tableScope
          }} />
      </x-div>
    );
  }

  public view(): VNode {
    return (
      <x-div width="100%">
        <a-space style={{width: "100%"}} direction="vertical">
          {this.searchForm()}
          {this.table()}
          {this.addModal()}
          {this.editModal()}
        </a-space>
      </x-div>
    );
  }
}