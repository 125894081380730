import StringUtil from "./StringUtil";

export default class ObjectUtil {
  /**
   * 获取类的名字
   * @param obj -- 类实例
   */
  public static getName(obj: Object): string {
      return obj.constructor.name;
  }

  /**
     * 获取方法的名称
     * @param obj --- 方法名称
     */
  public static getFuncName(obj: Function): string {
    return obj.name;
  }

  /**
  * 判断是不是对象
  * @param {*} obj: any --- 需要判断的对象
  * @return {*} boolean --- 是对象 ： true, 不是对象: false
  */
  public static isObject(obj: any) {
    return Object.prototype.toString.call(obj) === '[object Object]'
  }

  /**
  * 判断是不是数组
  * @param {*} arr: any --- 需要判断的对象
  * @return {*} boolean --- 是数组 ： true, 不是数组: false
  */
  public static isArray(arr: any): boolean {
    return Object.prototype.toString.call(arr) === '[object Array]'
  }

  /**
  * 判断是不是字符串
  * @param {*} arr: any --- 需要判断的对象
  * @return {*} boolean --- 是数组 ： true, 不是数组: false
  */
  public static isString(val: any): boolean {
    return Object.prototype.toString.call(val) === '[object String]'
  }

  public static isNull(obj: any): boolean {
    if (obj === null || obj === undefined) return true;

    return false;
  }

  /**
   * 过滤对象中的空值
   * @param oldVal 需要过滤的对象
   * @returns 
   */
  public static filterNull(oldVal: any): Object {
    for(const key in oldVal){
      if(StringUtil.isEmpty(oldVal[key])){
        delete oldVal[key]
      }
    }
    return oldVal;
  }

  /**
   * 深度复制对象
   * @param oldVal 需要复制的对象
   * @returns 
   */
  public static deepCopy(...oldVal: any[]): any {
    const result = Object.create(null)
    oldVal.forEach((obj: any) => {
      if (obj) {
        Object.keys(obj).forEach(key => {
          const val = obj[key]
          if (ObjectUtil.isObject(val)) {
            // 递归
            if (ObjectUtil.isObject(result[key])) {
              result[key] = ObjectUtil.deepCopy(result[key], val)
            } else {
              result[key] = ObjectUtil.deepCopy(val)
            }
          } else {
            result[key] = val
          }
        })
      }
    })
    return result;
  }

  /**
   * 深度复制对象数组
   * @param oldVal 需要复制的对象
   * @returns 
   */
   public static deepCopyArr(oldVal: any): any {
    const result = new Array<any>();
    oldVal.map((obj: any) => {
      result.push(ObjectUtil.deepCopy(obj));
    })
    return result;
  }
}