import ObjectUtil from "./ObjectUtil";
import md5 from 'js-md5';
import moment from "moment";

/**
 * 字符串处理类
 */
export default class StringUtil {
  /**
   * 是否空值
   * @param val 
   * @returns 
   */
  public static isEmpty(val: any, contactNull: boolean = true): boolean {
    // null or undefined
    if (contactNull && val === null) {
      return true;
    }

    if (val === undefined) return true;
  
    if (typeof val == 'boolean') return false;
    
    if (val instanceof Error) return val.message === '';
  
    switch (Object.prototype.toString.call(val)) {
      // String or Array
      case '[object String]':
      case '[object Array]':
        return !val.length;
      // Map or Set or File
      case '[object File]':
      case '[object Map]':
      case '[object Set]': {
        return !val.size;
      }
      // Plain Object
      case '[object Object]': {
        if (val instanceof moment) {
          return false;
        } else {
          return !Object.keys(ObjectUtil.filterNull(val)).length;
        }
      }
    }
  
    return false;
  }

  //时间转时间戳
  public static timestampToTime(times: string | number, format: string) {
    const timestamp = parseInt(times + "");

    if (StringUtil.isEmpty(times) || timestamp == 0) {
        return "无";
    }

    const jsdate = ((timestamp) ? new Date(timestamp) : new Date()); 
    
    const tf = (i: number) => {return (i < 10 ? '0' : '') + i};  

    return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a: string): string {  
        switch(a){  
            case 'yyyy':  
                return tf(jsdate.getFullYear());  
            case 'MM':  
                return tf(jsdate.getMonth() + 1);  
            case 'mm':  
                return tf(jsdate.getMinutes());
            case 'dd':  
                return tf(jsdate.getDate());
            case 'HH':  
                return tf(jsdate.getHours());
            case 'ss':  
                return tf(jsdate.getSeconds());
            default:
              return "";
        }
    })
  }

  /**
   * 获取随机数
   * @param start 
   * @param end 
   * @returns 
   */
  public static getRandom(start: number, end: number) {
    return Math.floor(Math.random()*(end - start + 1)+start);
  }

  /**
   * md5加密
   * @param val 
   * @returns 
   */
  public static md5(val: string): string {
    return md5(val);
  }

  /**
   * 验证手机号码
   * @param mobile 需验证的手机号码
   * @returns 
   */
	public static checkModbile(mobile: string) {
		const re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
		const result = re.test(mobile); 
		if(!result) {
			return false;
    }
		return true;
	}


}