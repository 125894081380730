import { VNode } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import LoggerUtil from '../utils/LoggerUtil';

@Component
export default class BaseView extends Vue {
  private isLoadding: boolean = true;

  public created(): void {
    this.initData().then(res => {
      if (res) {
        this.isLoadding = false;
      }
    }).catch(res => {
      LoggerUtil.error(this, res, "created error!!!!");
    });
  }

  async initData(): Promise<boolean> {
    return true;
  }

  public routerLink(url: string, params?: {}): void {
    this.$router.push({path: url, query: params}, () => {}, () => {});
  }

  public routerBack(): void {
    this.$router.go(-1);
  }

  public mounted(): void {}

  public updated(): void {}

  public destroyed(): void {}

  public view(): VNode {
    return (<x-div></x-div>);
  }

  public render(): VNode {
    if (this.isLoadding) {
      return (
        <x-div width="100%" height="100%" flex="col|center">
          <a-spin tip="Loading..." size="large" />
        </x-div>
      );
    } else {
      return this.view();
    }
  }
}
