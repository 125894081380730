import routeMap from '@/config/route.config';
import JsonUtil from '@/libs/utils/JsonUtil';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const menuList = JsonUtil.parse(sessionStorage.menu);

const routersArr: any = [];
routeMap.map((routeItem) => {
  if (["login"].indexOf(routeItem.name) == -1 ) {
    const filterArr = menuList?.filter((menuItem: any) => routeItem.name == menuItem.tips);
    
    if (filterArr?.length > 0) {
      const subMenu = filterArr[0].subMenu;
      const childRoutersArr: any = [];
      routeItem.children?.map((childItem: any) => {
        const childFilterArr = subMenu?.filter((chldMenuItem: any) => childItem.name == chldMenuItem.tips);
        if (childFilterArr.length > 0) {
          childRoutersArr.push(childItem);
        } else {
          if (["score", "sections", "recordedSchool", "coursework", "studentTraining", "studentActivity", "attendance", "courseDetail", "studentDetail", "evaluateResult"].indexOf(childItem.name) > -1) {
            childRoutersArr.push(childItem);
          }
        }
      })

      if (childRoutersArr.length > 0) {
        routeItem.children = childRoutersArr;
      } else {
        delete routeItem.children;
      }
      routersArr.push(routeItem);
    }
  } else {
    routersArr.push(routeItem);
  }
});

const routes: Array<RouteConfig> = routersArr.map((mapItem: any) => {
    return {
      path: mapItem.path,
      name: mapItem.name,
      component: mapItem.component,
      children: mapItem.children?.map((childItem: any) => {
        return {
          path: childItem.path,
          name: childItem.name,
          meta: childItem.preMata,
          component: childItem.component,
        }
      })
    };
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
