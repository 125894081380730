import { VNode } from "vue";
import { Component } from "vue-property-decorator";
import BaseView from "./libs/cores/BaseView";
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

@Component
export default class App extends BaseView {
  private locale: any = zhCN;
  public view(): VNode {
    return (
      <a-config-provider locale={this.locale}>
        <x-div id="app" width="100vw" height="100vh" flex="col|start" overflow="hidden">
          <router-view/>
        </x-div>
      </a-config-provider>
    );
  }
}
