import BaseBean from "../cores/BaseBean";
import StringUtil from "./StringUtil";

export default class JsonUtil {

  /**
   * 判断是否JSON字符串
   * @param str -- 欲判断的字符串
   */
  public static isJSON(str: string): boolean {
      if (typeof str == 'string') {
          try {
              const obj = JSON.parse(str);
              if (typeof obj == 'object' && obj) {
                  return true;
              } else {
                  return false;
              }
          } catch(e) {
              return false;
          }
      }
      return false;
  }

  /**
   * 字符串转换成对象
   * @param str -- 要转换的字符串
   */
  public static parse(str: string): any {
      if (JsonUtil.isJSON(str)) {
          return JSON.parse(str);
      }

      if (typeof str != "string") {
          return str;
      }

      if (StringUtil.isEmpty(str)) {
          str = "{}";
      }
      
      return eval("(" + str + ")");
  }

  /**
   * json转换成bean
   * @param str 
   * @param type 
   * @returns 
   */
  public static JsonToBean<T extends BaseBean>(str: string, type: {new(): T}): T | null {
    try {
      const obj = JsonUtil.parse(str);
      const tmp: any = new type();
      for (const i in obj) {
        const copy = obj[i];
        tmp[i] = copy
      }
      return tmp;
    } catch(e) {
      return null;
    }
  }

  public static JsonToList<T extends BaseBean>(str: string, type: {new(): T}): Array<T> {
    const obj = JsonUtil.parse(str);
    const resut: Array<T> = new Array<T>();

    if (obj instanceof Array) {
      obj.forEach(item => {
        const tmp = JsonUtil.JsonToBean(item, type);
        resut.push(tmp as T);
      });
    }

    return resut;
  }

  /**
   * 对象转换成字符串
   * @param obj -- 要转换的对象
   */
  public static stringify(obj: Object): string | null {
    try {
      return JSON.stringify(obj);
    } catch (error) {
      return null;
    }
  }
}