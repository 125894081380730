import config from "@/config/config";
import StringUtil from "./utils/StringUtil";

export function isPhoneDevice(): boolean {
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

  if (flag) {
    return true;
  } else {
    return false;
  }
}

/**
 * 初始化手机
 */
export function initMob(): void {
  if (!isPhoneDevice()) {
    return;
  }
  const baseWidth = 750;
  const baseFont  = 100;
  //获取屏幕宽度
  const htmlwidth = document.documentElement.clientWidth || document.body.clientWidth;
  
  // let root = <HTMLBodyElement> document.querySelector(':root');
  // root.style.setProperty('--vh-height', htmlHeight + "px");
  // 获取html的dom
  const htmldom = document.getElementsByTagName('html')[0];

  const baseFontSize = htmlwidth / baseWidth * baseFont;
  //设置html的font-size
  htmldom.style.fontSize = baseFontSize + 'px';
  const nowWidth = (document.getElementById("app") as HTMLDivElement)?.clientWidth;
  if (nowWidth !== htmlwidth) {
    htmldom.style.fontSize = baseFontSize * htmlwidth / nowWidth + 'px';
  }

  window.addEventListener('resize', (e: any) => {
    const htmlwidth = document.documentElement.clientWidth || document.body.clientWidth;
    htmldom.style.fontSize = htmlwidth / baseWidth * baseFont + 'px';
    const nowWidth = (document.getElementById("app") as HTMLDivElement).clientWidth;
    if (nowWidth !== htmlwidth) {
      htmldom.style.fontSize = (htmlwidth / baseWidth * baseFont) * htmlwidth / nowWidth + 'px';
    }
  })
}

/**
* @brief 如果单位是rem,将px转换成Rem
* @param {*} null
* @return {*} void
 */
export function getRemByPx(val: number): string {
  if (StringUtil.isEmpty(val)) {
    return "";
  }
  const baseDeviceWidth = 750; // 默认设备宽度，以些为基数进行计算
  const baseDeviceFontSize = 100; //默认设备字体大小，以些为基数计算
  let returnValue = val;
  if (isPhoneDevice()) {
    returnValue = (baseDeviceWidth / baseDeviceFontSize) * (val / baseDeviceWidth);
    return String((Math.ceil(returnValue * 1000) / 1000) ) + "rem";
  } else {
    return String(getPxByScreen(returnValue)) + "px";
  }
}

export function getPxByScreen(val: number): number {
  const htmlwidth = document.documentElement.clientWidth || document.body.clientWidth;

  if (config.isOpenScreenSwitch) {
    return Math.ceil((htmlwidth / config.baseCreenWidth)  * val);
  }

  return val;
}
