const routeMap = [
  {
    path: '/',
    name: 'login',
    mata: {title: "登录", icon: ""},
    isMenu: false,
    visite: true,
    component: () => import('@/presenters/LoginPresenter')
  },
  {
    path: '/manager',
    name: 'manager',
    mata: {title: "智慧院校", icon: ""},
    isMenu: true,
    component: () => import('@/presenters/IndexPresenter'),
    children: [
      {
        path: 'school',
        name: 'school',
        mata: {title: "院校管理", icon: ""},
        component: () => import('@/presenters/SchoolPresenter'),
      },
      {
        path: 'contact',
        name: 'contact',
        isMenu: false,
        preMata: {title: "院校管理", path: "school", name: "school"},
        mata: {title: "院校联系人", icon: ""},
        component: () => import('@/presenters/ContactPresenter'),
      },
      {
        path: 'major',
        name: 'major',
        isMenu: false,
        preMata: {title: "院校管理", path: "school", name: "school"},
        mata: {title: "院校专业", icon: ""},
        component: () => import('@/presenters/MajorPresenter'),
      },
      {
        path: 'classes',
        name: 'classes',
        mata: {title: "班级管理", icon: ""},
        component: () => import('@/presenters/ClassesPresenter'),
      },
      {
        path: 'course',
        name: 'course',
        isMenu: true,
        mata: {title: "课程管理", icon: ""},
        component: () => import('@/presenters/CoursePresenter'),
      },
      {
        path: 'schedule',
        name: 'schedule',
        isMenu: false,
        preMata: {title: "班级管理", path: "classes", name: "classes"},
        mata: {title: "课表管理", icon: ""},
        component: () => import('@/presenters/SchedulePresenter'),
      },
      {
        path: 'score',
        name: 'score',
        isMenu: false,
        preMata: {title: "班级管理", path: "classes", name: "classes"},
        mata: {title: "成绩管理", icon: ""},
        component: () => import('@/presenters/ScorePresenter'),
      },
      {
        path: 'teacher',
        name: 'teacher',
        mata: {title: "教师管理", icon: ""},
        component: () => import('@/presenters/TeacherPresenter'),
      },
      {
        path: 'student',
        name: 'student',
        mata: {title: "学生管理", icon: ""},
        component: () => import('@/presenters/StudentPresenter'),
      },
      {
        path: 'coursework',
        name: 'coursework',
        isMenu: false,
        preMata: {title: "学生管理", path: "student", name: "student"},
        mata: {title: "课业情况", icon: ""},
        component: () => import('@/presenters/CourseworkPresenter'),
      },
      {
        path: 'studentTraining',
        name: 'studentTraining',
        isMenu: false,
        preMata: {title: "学生管理", path: "student", name: "student"},
        mata: {title: "实训经历", icon: ""},
        component: () => import('@/presenters/StudentTrainingPresenter'),
      },
      {
        path: 'studentActivity',
        name: 'studentActivity',
        isMenu: false,
        preMata: {title: "学生管理", path: "student", name: "student"},
        mata: {title: "实训经历", icon: ""},
        component: () => import('@/presenters/StudentActivityPresenter'),
      },
      {
        path: 'activity1',
        name: 'activity1',
        mata: {title: "实训管理", icon: ""},
        component: () => import('@/presenters/ActivityPresenter'),
      },
      {
        path: 'activity2',
        name: 'activity2',
        mata: {title: "活动管理", icon: ""},
        component: () => import('@/presenters/ActivityPresenter'),
      },
      {
        path: 'activity3',
        name: 'activity3',
        mata: {title: "实习/就业管理", icon: ""},
        component: () => import('@/presenters/ActivityPresenter'),
      },
      {
        path: 'activityStudent',
        name: 'activityStudent',
        isMenu: false,
        preMata: {title: "实训/就业/实习/活动管理", path: "activity", name: "activity"},
        mata: {title: "参与学生", icon: ""},
        component: () => import('@/presenters/ActivityStudentPresenter'),
      },
      {
        path: 'studentLevel',
        name: 'studentLevel',
        mata: {title: "学生评教管理", icon: ""},
        component: () => import('@/presenters/StudentLevelPresenter'),
      },
      {
        path: 'recordedCurriculum',
        name: 'recordedCurriculum',
        mata: {title: "录播课管理", icon: ""},
        component: () => import('@/presenters/RecordedCurriculumPresenter'),
      },
      {
        path: 'sections',
        name: 'sections',
        isMenu: false,
        preMata: {title: "录播课管理", path: "recordedCurriculum", name: "recordedCurriculum"},
        mata: {title: "章节管理", icon: ""},
        component: () => import('@/presenters/SectionsPresenter'),
      },
      {
        path: 'recordedSchool',
        name: 'recordedSchool',
        isMenu: false,
        preMata: {title: "录播课管理", path: "recordedCurriculum", name: "recordedCurriculum"},
        mata: {title: "可见院校", icon: ""},
        component: () => import('@/presenters/RecordedSchoolPresenter'),
      },
      {
        path: 'attendance',
        name: 'attendance',
        isMenu: false,
        preMata: {title: "课程管理", path: "course", name: "course"},
        mata: {title: "考勤设置", icon: ""},
        component: () => import('@/presenters/AttendancePresenter'),
      },
      {
        path: 'courseDetail',
        name: 'courseDetail',
        isMenu: false,
        preMata: {title: "课程管理", path: "course", name: "course"},
        mata: {title: "考勤设置", icon: ""},
        component: () => import('@/presenters/CourseDetailPresenter'),
      },
      {
        path: 'studentDetail',
        name: 'studentDetail',
        isMenu: false,
        preMata: {title: "学生管理", path: "student", name: "student"},
        mata: {title: "学生详情", icon: ""},
        component: () => import('@/presenters/StudentDetailPresenter'),
      },
      {
        path: 'evaluateResult',
        name: 'evaluateResult',
        isMenu: false,
        preMata: {title: "学生评教管理", path: "studentLevel", name: "studentLevel"},
        mata: {title: "评价结果", icon: ""},
        component: () => import('@/presenters/EvaluateResultPresenter'),
      },
      {
        path: 'schoolSpirit',
        name: 'schoolSpirit',
        mata: {title: "校风校貌", icon: ""},
        component: () => import('@/presenters/SchoolSpirit.vue'),
      },
    ]
  },
];

export default routeMap;