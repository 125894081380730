import config from "@/config/config";
import ObjectUtil from "./ObjectUtil";
import StringUtil from "./StringUtil";

/**
 * 日志处理类
 */
export default class LoggerUtil {
  public static preConsole(obj: Object): void {
    const objName = ObjectUtil.getName(obj);
    const consoleArr = new Array<{msg: any, styles ?: Array<string>}>();
    consoleArr.push({msg: "<---- class: " + objName + " console ---->", styles: ["color: gray"]});
    LoggerUtil.log(consoleArr);
  }

  public static afterConsole(obj: Object): void {
    const objName = ObjectUtil.getName(obj);
    const consoleArr = new Array<{msg: any, styles ?: Array<string>}>();
    consoleArr.push({msg: "<---- class: " + objName + " end console ---->", styles: ["color: gray"]});
    LoggerUtil.log(consoleArr);
  }

  public static info(obj: Object, val: any, tag?: string): void {
    LoggerUtil.preConsole(obj);
    const consoleArr = new Array<{msg: any, styles ?: Array<string>}>();
    if (!StringUtil.isEmpty(tag)) {
      consoleArr.push({msg: tag + ":", styles: ["color: black"]});
    }
    consoleArr.push({msg: val, styles: ["color: green"]});
    LoggerUtil.log(consoleArr);
    LoggerUtil.afterConsole(obj);
  }

  public static error(obj: Object, val: any, tag?: string): void {
    LoggerUtil.preConsole(obj);
    const consoleArr = new Array<{msg: any, styles ?: Array<string>}>();
    if (!StringUtil.isEmpty(tag)) {
      consoleArr.push({msg: tag + ":", styles: ["color: black"]});
    }
    consoleArr.push({msg: val, styles: ["color: red"]});
    LoggerUtil.log(consoleArr);
    LoggerUtil.afterConsole(obj);
  }

  public static log(val: Array<{msg: any, styles ?: Array<string>}>): void {
    const isDebug = config.isDebug;
    if (isDebug) {
      val.map((item) => ObjectUtil.isString(item.msg) ? console.log("%c" + item.msg, item.styles?.join("")) : console.log(item.msg));
    }
  }
}