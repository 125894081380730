import { Vue } from 'vue-property-decorator';
import StringUtil from './utils/StringUtil';
import XDiv from './widgets/XDiv';
import XForm from './widgets/XForm';
import XImage from './widgets/XImage';
import XTable from './widgets/XTable';

export default {
  install: function(vue: any, options: any) {
    vue.prototype.StringUtil = StringUtil;
    
    Vue.component("XDiv", XDiv);
    Vue.component("XImage", XImage);
    Vue.component("XTable", XTable);
    Vue.component("XForm", XForm);
  }
}