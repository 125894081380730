import { VNode } from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import BaseView from "../cores/BaseView";
import { getRemByPx } from "../global";
import ObjectUtil from "../utils/ObjectUtil";
import StringUtil from "../utils/StringUtil";

@Component
export default class XDiv extends BaseView {
  @Prop({ type: String, default: "" })
  public readonly flex?: string;

  @Prop({ type: [String, Number], default: null })
  public readonly width?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly height?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly lineHeight?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly fontSize?: string | number;

  @Prop({ type: String, default: null })
  public readonly color?: string;

  @Prop({ type: [String, Number], default: null })
  public readonly fontWeight?: string | number;

  @Prop({ type: String, default: null })
  public readonly textAlign?: "center" | "left" | "right";

  @Prop({ type: [String, Array], default: null })
  public readonly margin?: string | [number, number, number, number];

  @Prop({ type: [String, Number], default: null })
  public readonly marginTop?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly marginLeft?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly marginRight?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly marginBottom?: string | number;

  @Prop({ type: [String, Array], default: null })
  public readonly padding?: string | [number, number, number, number];

  @Prop({ type: [String, Number], default: null })
  public readonly paddingTop?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly paddingLeft?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly paddingRight?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly paddingBottom?: string | number;

  @Prop({ type: String, default: null })
  public readonly overflow?: "hidden" | "auto" | "scroll";

  @Prop({ type: String, default: null })
  public readonly overflowX?: "hidden" | "auto" | "scroll";

  @Prop({ type: String, default: null })
  public readonly overflowY?: "hidden" | "auto" | "scroll";

  @Prop({ type: String, default: null })
  public readonly position?: string;

  @Prop({ type: [String, Number], default: null })
  public readonly left?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly top?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly right?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly bottom?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly zIndex?: string | number;

  @Prop({ type: [Object, Array] , default: null })
  public readonly bg?: string | { url: string } | Array<string | { url: string}>;

  @Prop({ type: [String, Array], default: null })
  public readonly bgSize?: string | [number, number];

  @Prop({ type: String, default: null })
  public readonly bgColor?: string;

  @Prop({ type: [String, Array], default: null })
  public readonly bgPosition?: string | [number, number];

  @Prop({ type: String, default: null })
  public readonly border?: string;

  @Prop({ type: [String, Number, Array], default: null })
  public readonly borderRadius?: string | number | [number, number, number, number];

  @Emit("bindref")
  public _bindref(ref: any): void {}

  @Emit("click")
  private _click(): void {}

  public mounted(): void {
    this._bindref(this.$refs.divRef);
  }

  public getVal(val: string | number | undefined): string {
    return ObjectUtil.isString(val) ? String(val) : getRemByPx(val as number);
  }

  public baseBinder(): string {
    
    const ret: Array<string> = new Array<string>();
    if (!StringUtil.isEmpty(this.width)) {
      
      ret.push("width: " + this.getVal(this.width)  + ";");
    }
  
    if (!StringUtil.isEmpty(this.height)) {
      ret.push("height: " + this.getVal(this.height) + ";");
    } else {
      ret.push("height: auto;");
    }
  
    if (!StringUtil.isEmpty(this.lineHeight)) {
      ret.push("line-height: " + this.getVal(this.lineHeight) + ";");
    }
  
    if (!StringUtil.isEmpty(this.fontSize)) {
      ret.push("font-size: " + this.getVal(this.fontSize) + ";");
      if (StringUtil.isEmpty(this.lineHeight) && !StringUtil.isEmpty(this.height)) {
        ret.push("line-height: " + this.getVal(this.height) + ";");
      }
    }
  
    if (!StringUtil.isEmpty(this.color)) {
      ret.push("color: " + this.color + ";");
    }
  
    if (!StringUtil.isEmpty(this.fontWeight)) {
      ret.push("font-weight: " + this.getVal(this.fontWeight) + ";");
    }
  
    if (!StringUtil.isEmpty(this.textAlign)) {
      ret.push("text-align: " + this.textAlign + ";");
    }
  
    return ret.join(" ");
  }

  public flexBinder(): string {
    const flexColValue: {[key: string]: any} = {
      "base": "display: flex; flex-direction: column; box-sizing: border-box;",
      "center": "align-items: center; justify-content: center;",
      "center-x": "align-items: center;",
      "start-x": "align-items: flex-start;",
      "end-x": "align-items: flex-end;",
      "center-y": "justify-content: center;",
      "start-y": "justify-content: flex-start;",
      "end-y": "justify-content: flex-end;",
      "between": "justify-content: space-between;",
      "grow": "flex-grow: 1;",
      "shrink": "flex-shrink: 0;",
      "flow" : "flex-flow: 1;",
      "wrap": "flex-wrap: wrap;",
    };
  
    const flexRowValue: {[key: string]: any} = {
      "base": "display: flex; flex-direction: row; box-sizing: border-box;",
      "center": "align-items: center; justify-content: center;",
      "center-x": "justify-content: center;",
      "start-x": "justify-content: flex-start;",
      "end-x": "justify-content: flex-end;",
      "center-y": "align-items: center;",
      "start-y": "align-items: flex-start;",
      "end-y": "align-items: flex-end;",
      "between": "justify-content: space-between;",
      "grow": "flex-grow: 1;",
      "shrink": "flex-shrink: 0;",
      "flow" : "flex-flow: 1;",
      "wrap": "flex-wrap: wrap;",
    };
  
    const ret: Array<string> = new Array<string>();
    if (!StringUtil.isEmpty(this.flex)) {
      const flexArr = this.flex?.split("|");
      let tmpStyle = "";
      let styleArr: {[key: string]: any} = {};
      flexArr?.map((item: string) => {
        if (item === "col") {
          styleArr = flexColValue;
          tmpStyle = styleArr.base;
        } else if (item === "row") {
          styleArr = flexRowValue;
          tmpStyle = styleArr.base;
        } else {
          if (StringUtil.isEmpty(styleArr[item])) {
            tmpStyle += flexColValue[item];
          } else {
            tmpStyle += styleArr[item];
          }
        }
        return item;
      });
  
      if (tmpStyle.indexOf("align-items") === -1) {
        tmpStyle += "align-items: flex-start;";
      }
  
      if (tmpStyle.indexOf("justify-content") === -1) {
        tmpStyle += "justify-content: flex-start;";
      }
  
      ret.push(tmpStyle);
    }
    return ret.join(" ");
  }

  public marginBinder(): string {
    const ret: Array<string> = new Array<string>();
    if (!StringUtil.isEmpty(this.margin)) {
      if (ObjectUtil.isArray(this.margin)) {
        const marginArr = this.margin as [number, number, number, number];
        ret.push("margin: " + getRemByPx(marginArr[0]) + " " + getRemByPx(marginArr[1]) + " " + getRemByPx(marginArr[2]) + " " + getRemByPx(marginArr[3]) + " " + ";");
      } else {
        ret.push("margin: " + this.margin + ";");
      }
    } else {
      if (!StringUtil.isEmpty(this.marginTop)) {
        ret.push("margin-top: " + this.getVal(this.marginTop) + ";");
      }
  
      if (!StringUtil.isEmpty(this.marginRight)) {
        ret.push("margin-right: " + this.getVal(this.marginRight) + ";");
      }
  
      if (!StringUtil.isEmpty(this.marginLeft)) {
        ret.push("margin-left: " + this.getVal(this.marginLeft) + ";");
      }
  
      if (!StringUtil.isEmpty(this.marginBottom)) {
        ret.push("margin-bottom: " + this.getVal(this.marginBottom) + ";");
      }
    }
    return ret.join(" ");
  }

  public paddingBinder(): string {
    const ret: Array<string> = new Array<string>();
    if (!StringUtil.isEmpty(this.padding)) {
      if (ObjectUtil.isArray(this.padding)) {
        const paddingArr = this.padding as [number, number, number, number]
        ret.push("padding: " + getRemByPx(paddingArr[0]) + " " + getRemByPx(paddingArr[1]) + " " + getRemByPx(paddingArr[2]) + " " + getRemByPx(paddingArr[3]) + " " + ";");
      } else {
        ret.push("padding: " + this.padding + ";");
      }
    } else {
      if (!StringUtil.isEmpty(this.paddingTop)) {
        ret.push("padding-top: " + this.getVal(this.paddingTop) + ";");
      }
  
      if (!StringUtil.isEmpty(this.paddingRight)) {
        ret.push("padding-right: " + this.getVal(this.paddingRight) + ";");
      }
  
      if (!StringUtil.isEmpty(this.paddingLeft)) {
        ret.push("padding-left: " + this.getVal(this.paddingLeft) + ";");
      }
  
      if (!StringUtil.isEmpty(this.paddingBottom)) {
        ret.push("padding-bottom: " + this.getVal(this.paddingBottom) + ";");
      }
    }
    return ret.join(" ");
  }

  public overflowBinder(): string {
    const ret: Array<string> = new Array<string>();
    if (!StringUtil.isEmpty(this.overflow)) {
      ret.push("overflow: " + this.overflow + ";");
    } else {
      if (!StringUtil.isEmpty(this.overflowX)) {
        ret.push("overflow-x: " + this.overflowX + ";");
      }
  
      if (!StringUtil.isEmpty(this.overflowY)) {
        ret.push("overflow-y: " + this.overflowY + ";");
      }
    }
    return ret.join(" ");
  }

  public positionBinder(): string {
    const positionValue: {[key: string]: any} = {
      "absolute": "position: absolute;",
      "relative": "position: relative;",
      "fixed": "position: fixed;",
    };
    const ret: Array<string> = new Array<string>();
    if (!StringUtil.isEmpty(this.position)) {
      ret.push(positionValue[this.position as string]);
  
      if (!StringUtil.isEmpty(this.top)) {
        ret.push("top: " + this.getVal(this.top) + ";");
      }
  
      if (!StringUtil.isEmpty(this.left)) {
        ret.push("left: " + this.getVal(this.left) + ";");
      }
  
      if (!StringUtil.isEmpty(this.right)) {
        ret.push("right: " + this.getVal(this.right) + ";");
      }
  
      if (!StringUtil.isEmpty(this.bottom)) {
        ret.push("bottom: " + this.getVal(this.bottom) + ";");
      }
    }
  
    if (!StringUtil.isEmpty(this.zIndex)) {
      ret.push("z-index: " + this.zIndex + ";");
    }
    return ret.join(" ");
  }

  public getBgString(val:string | { url: string}): string {
    if (ObjectUtil.isString(val)) {
      return val as string;
    } else if (ObjectUtil.isObject(val) && !StringUtil.isEmpty((val as {url: string}).url)) {
      return "url('" + (val as {url: string}).url + "')";
    } else {
      return "";
    }
  }
  
  public bgBinder(): string {
    const ret: Array<string> = new Array<string>();
    if (!StringUtil.isEmpty(this.bg)) {
      if (ObjectUtil.isArray(this.bg)) {
        const tmpStrArr = new Array<string>();
        (this.bg as Array<string | { url: string}>).map((item) => {
          tmpStrArr.push(this.getBgString(item as string | { url: string}));
          return "";
        });
        ret.push("background-image: " + tmpStrArr.join(",") + ";");
      } else {
        ret.push("background-image: " + this.getBgString(this.bg as string | { url: string}) + ";");
      }
      ret.push("background-repeat: no-repeat;");
    }
  
    if (!StringUtil.isEmpty(this.bgSize)) {
      if (ObjectUtil.isArray(this.bgSize)) {
        const sizeArr = this.bgSize as [number, number]
        ret.push("background-size: " + getRemByPx(sizeArr[0]) + " " + getRemByPx(sizeArr[1]) + ";");
      } else {
        ret.push("background-size: " + this.bgSize + ";");
      }
    } else {
      if (!StringUtil.isEmpty(this.bg)) {
        ret.push("background-size: 100% 100%;");
      }
    }
  
    if (!StringUtil.isEmpty(this.bgColor)) {
      ret.push("background-color: " + this.bgColor + ";");
    }

    if (!StringUtil.isEmpty(this.bgPosition)) {
      if (ObjectUtil.isArray(this.bgPosition)) {
        const sizeArr = this.bgPosition as [number, number]
        ret.push("background-position: " + getRemByPx(sizeArr[0]) + " " + getRemByPx(sizeArr[1]) + ";");
      } else {
        ret.push("background-position: " + this.bgPosition + ";");
        
      }
    }
    return ret.join(" ");
  }
  
  public borderBinder(): string {
    const ret: Array<string> = new Array<string>();
    if (!StringUtil.isEmpty(this.border)) {
      ret.push("border: " + this.border + ";");
    } else {
      ret.push("border: none;");
    }
  
    if (!StringUtil.isEmpty(this.borderRadius)) {
      if (ObjectUtil.isArray(this.borderRadius)) {
        const borderArr = this.borderRadius as [number, number, number, number]
        ret.push("border-radius: " + getRemByPx(borderArr[0]) + " " + getRemByPx(borderArr[1]) + " " + getRemByPx(borderArr[2]) + " " + getRemByPx(borderArr[3]) + " " + ";");
      } else {
        ret.push("border-radius: " + this.getVal(this.borderRadius as number | string) + ";");
      }
      
    }
    return ret.join(" ");
  }

  render(): VNode {
    const ret: Array<string> = new Array<string>();
    ret.push(this.baseBinder());
    ret.push(this.flexBinder());
    ret.push(this.marginBinder());
    ret.push(this.paddingBinder());
    ret.push(this.overflowBinder());
    ret.push(this.positionBinder());
    ret.push(this.bgBinder());
    ret.push(this.borderBinder());
    const style = ret.join(" ");
    return (
      <div ref="divRef" style={style} onClick={this._click}>{this.$slots.default}</div>
    );
  }
}