import { VNode } from "vue";
import { Component, Prop } from "vue-property-decorator";
import BaseView from "../cores/BaseView";

@Component
export default class XImage extends BaseView {
  @Prop({ type: [String, Number], default: null })
  public readonly width?: string | number;

  @Prop({ type: [String, Number], default: null })
  public readonly height?: string | number;

  @Prop({ type: String, default: null })
  public readonly src?: string;

  render(): VNode {
    return (
      <x-div width={this.width} height={this.height}>
        <img src={this.src} width="100%" height="100%" />
      </x-div>
    );
  }
}